exports.components = {
  "component---src-sites-type-background-antrejka-templates-add-domain-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-add-domain-tsx" */),
  "component---src-sites-type-background-antrejka-templates-article-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/Article.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-article-tsx" */),
  "component---src-sites-type-background-antrejka-templates-contact-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-contact-tsx" */),
  "component---src-sites-type-background-antrejka-templates-home-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-home-tsx" */),
  "component---src-sites-type-background-antrejka-templates-not-found-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-not-found-tsx" */),
  "component---src-sites-type-background-antrejka-templates-search-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-search-tsx" */),
  "component---src-sites-type-background-antrejka-templates-terms-tsx": () => import("./../../../src/sites/typeBackground/antrejka/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-background-antrejka-templates-terms-tsx" */)
}

